@mixin media-up($device) {
    @if ($device == 'desktop'){
        @media (min-width: 960px) {
            @content
        }
    }
    @if ($device == 'desktop-lg'){
        @media (min-width: 1280px) {
            @content
        }
    }
}

@mixin media-down($device) {
    @if ($device == 'desktop'){
        @media (max-width: 960px) {
            @content
        }
    }
    @if ($device == 'desktop-lg'){
        @media (max-width: 1280px) {
            @content
        }
    }
}