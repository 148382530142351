.table-scroll{
  width:calc(100% - 24px);
    @media(max-width: 959px) {
    height: calc(100vh - 20em) !important;
    overflow: auto;
    }
}
.mat-table{
    width:100%;
    tr.mat-header-row {
        height: 2.5em;
    }
    th.mat-header-cell{
        font-family: 'Avenir-Heavy',sans-serif;
        color: map-get($cartus-palette, 'cartus_gray');
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: map-get($cartus-palette, 'cartus_secondary_gray');
        padding: 0.625em;
        font-size: 0.75em;
        vertical-align: bottom;
        padding-bottom: 0.625em;
        font-weight: normal;
        min-width: 6.25em;
        &:last-of-type{
            border-right-width: 0;
            padding-right: 0.313em;
        }
        &:first-of-type{
            padding-left: 0.313em;
        }
        .mat-sort-header-arrow {
            color: map-get($cartus-palette, 'cartus_gray');
        }
    }
    tbody{
        .mat-row{
            &:hover{
                background-color: map-get($cartus-palette, 'cartus_hover_tint');
                cursor: pointer;
            }
        }
    }
    td.mat-cell{
        font-family: 'Avenir-Book',sans-serif;
        min-width: 6.25em;
        font-size: 1em;
        color: map-get($cartus-palette,'cartus-gray');
        padding: 0.625em;
        line-height: 1.25em;
        vertical-align: top;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: map-get($cartus-palette, 'cartus_light_warm_gray');
        &:last-of-type{
            border-right-width: 0;
            padding-right: 0.313em;
        }
        &:first-of-type{
            padding-left: 0.313em;
        }
        p{
            margin:0;
            &.link-color {
                color: map-get($cartus-palette, 'cartus_link_color');
                cursor: pointer;
            }
        }
        a{
            margin: 0;
            color: map-get($cartus-palette, 'cartus_link_color');
            display: flex;
            cursor: pointer;
        }
    }
}

.mat-paginator-container{
    font-size: 0.75em;
    background-color: white;
    .mat-paginator-page-size{
        position: relative !important;
        margin:0px;
        .mat-form-field-wrapper{
            margin:0px;
        }
    }
  }

.mat-cell.clickable {
    cursor: pointer;
}
th.mat-header-cell:focus {
    background-color: #f1f1f1;
}
